import { useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { useAxios } from '../../../AxiosProvider';
import UserHealthFormCard from '../../../components/FormCard/UserHealthFormCard';
import Spinner from '../../../components/Spinner';

const HealthForm = () => {
    const { userId } = useParams();
    const axios = useAxios();
    const navigate = useNavigate();
    const [showSuccess, setShowSuccess] = useState(false);

    const onComplete = () => {
        setShowSuccess(true);
        setTimeout(() => {
            navigate('/form', { replace: true });
        }, 3000);
    };

    const addPoliceHealth = useMutation(async (userDetails) => {
        const response = await axios
            .post('UsersHealth/add_user_health', JSON.stringify(userDetails))
            .then((res) => {
                if (res.data.success) {
                    onComplete();
                    // navigate(`/form/posting/${userId}`, { replace: true });
                }
            })
            .catch(function (error) {
                console.log('API calling error', error);
            });
        return response;
    });

    if (showSuccess) {
        return (
            <>
                <h1 className="text-4xl text-center text-green-500 font-normal leading-normal mt-0 mb-2 md:px-6">
                    {'Police is added successfully!'}
                </h1>
                <p className="text-center font-normal leading-normal mt-0 mb-2 md:px-6">
                    {'To add new one, Please refresh the page.'}
                </p>
                <p className="text-xl text-center font-normal leading-normal mt-0 mb-2 md:px-6">
                    {'Thank You!'}
                    <br />
                    {'जिल्हा पोलीस'}
                </p>
            </>
        );
    }

    if (addPoliceHealth.error) {
        return (
            <h1 className="text-4xl text-center text-red-500 font-normal leading-normal mt-0 mb-2 md:px-6">
                {'Something went wrong, refresh and try again!'}
            </h1>
        );
    }

    if (addPoliceHealth.isLoading) {
        return <Spinner />;
    }

    return (
        <div className="mx-auto w-full">
            <h5 className="md:px-10  text-xl font-normal leading-normal my-2 px-6 text-center">
                {'वैद्यकीय माहिती'}
            </h5>
            <hr className="border border-gray-300" />
            <p className="md:px-10 text-sm leading-normal my-2 px-6 text-justify">
                {
                    'तुम्हाला माहिती असलेल्या बिमारी बद्दल इथे टिक करायचं आहे. या माहिती चा उपयोग पोलीस दला अंतर्गत चालविण्यात येणाऱ्या योजना साठी केल्या जाणार त्यामुळे अचूक माहिती वर टिक करावे.. तुमची हि माहिती दुसरे कुणीही बघू शकत नाही तर त्या बद्दल चिंता करायची गरज नाही.'
                }
            </p>
            <hr className="border border-gray-300" />
            <UserHealthFormCard
                user={{
                    user_role_id: 3,
                }}
                mutate={addPoliceHealth.mutate}
                defaultUserId={userId}
            />
        </div>
    );
};

export default HealthForm;
