import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import UserTrainingFormCard from '../../../components/FormCard/UserTrainingFormCard';

const TrainingForm = () => {
    const navigate = useNavigate();
    const [showSuccess, setShowSuccess] = useState(false);

    if (showSuccess) {
        return (
            <>
                <h1 className="text-4xl text-center text-green-500 font-normal leading-normal mt-0 mb-2 md:px-6">
                    {'Police is added successfully!'}
                </h1>
                <p className="text-center font-normal leading-normal mt-0 mb-2 md:px-6">
                    {'To add new one, Please refresh the page.'}
                </p>
                <p className="text-xl text-center font-normal leading-normal mt-0 mb-2 md:px-6">
                    {'Thank You!'}
                    <br />
                    {'जिल्हा पोलीस'}
                </p>
            </>
        );
    }

    const onComplete = () => {
        setShowSuccess(true);
        setTimeout(() => {
            navigate('/form', { replace: true });
        }, 3000);
    };

    return (
        <div className="mx-auto w-full">
            <h5 className="md:px-10  text-xl font-normal leading-normal my-2 px-6 text-center">
                {
                    'तुम्ही आजपर्यंत पोलीस दलाअंतर्गत घेतलेल्या ट्रेनिंग बद्दल माहिती खाली भरायची आहे.'
                }
            </h5>
            <hr className="border border-gray-300" />
            <p className="md:px-10 text-sm leading-normal my-2 px-6 text-justify">
                {
                    'नोट : माहिती भरण्यासाठी ऍड ट्रेनिंग च्या बटण वर क्लिक करायचे आहे. तुम्ही एका नंतर एक किती पण ट्रेनिंग बद्दल माहिती इथे भरू शकता. एका ट्रेनिंग बद्दल माहिती भरून झाल्या नंतर तुम्ही परत ऍड ट्रेनिंग बटण वर क्लिक करून परत दुसऱ्या ट्रेनिंग बद्दल माहिती भरू शकता. माहिती भरत असताना जुन्या ते नवीनतम झालेल्या ट्रेनिंग बदल माहिती भरत आणायचे आहे.'
                }
            </p>
            <hr className="border border-gray-300" />
            <UserTrainingFormCard
                user={{
                    user_role_id: 3,
                }}
            />
            <div className="w-full px-4 lg:order-3 lg:text-right lg:self-center">
                <div className="py-6 px-3 text-center">
                    <button
                        onClick={onComplete}
                        className="bg-police-blue active:bg-yellow-400 text-white font-bold hover:shadow-md shadow text-xls px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150"
                        type="button"
                    >
                        {'Complete'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TrainingForm;
