import { filter, reduce } from 'lodash';
import React, { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router';

import useFetchUserDetailsById from '../../hooks/useFetchUserDetailsById';
import usePostCreateUser from '../../hooks/usePostCreateUser';
import usePostUpdateUser from '../../hooks/usePostUpdateUser';
import FormView from '../../pages/PublicPage/FormView';
import Form from '../Form/Form';
import InputProfile from '../Form/InputProfile';
import Spinner from '../Spinner';
import UserAcrDetailsFormCard from './UserAcrDetailsFormCard';
import UserDeputationFormCard from './UserDeputationFormCard';
import UserPostingFormCard from './UserPostingFormCard';
import UserPunishmentFormCard from './UserPunishmentFormCard';
import UserRankJoiningFormCard from './UserRankJoiningFormCard';
import UserRewardFormCard from './UserRewardFormCard';
import UserSportFormCard from './UserSportFormCard';
import UserSuspensionFormCard from './UserSuspensionFormCard';
import UserTrainingFormCard from './UserTrainingFormCard';
import { inputFields, userSchema } from './inputFields/userFormFields';
import { useGeoCode } from './useGeoCode';

const getDefaultRoleId = {
    1: '2',
    2: '3',
    3: '4',
};

export const UserForm = ({
    userRoles,
    viewOnly,
    viewProfile,
    updating,
    userById,
    policeAdminOptions,
    policeStationOptions,
    mutate,
}) => {
    const [updatedUser, setUpdatedUser] = useState(userById);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const createUser = usePostCreateUser({
        onSuccess: () => {
            navigate(-1);
        },
    });
    const updateUser = usePostUpdateUser({
        onSuccess: () => {
            navigate(-1);
        },
    });

    const handleUpdate = (value) => {
        setUpdatedUser((uu) => ({
            ...uu,
            ...value,
        }));
    };

    const submitData = async () => {
        const handleMutate = mutate
            ? mutate
            : updating
              ? updateUser.mutate
              : createUser.mutate;

        try {
            await userSchema(
                policeAdminOptions || policeStationOptions,
                updatedUser,
            ).validate(updatedUser, { abortEarly: false });
            setErrors({});
            handleMutate(updatedUser);
        } catch (err) {
            console.log(
                reduce(
                    err.inner,
                    (acc, val) => ({
                        ...acc,
                        [val.path]: val.message,
                    }),
                    {},
                ),
            );
            setErrors(
                reduce(
                    err.inner,
                    (acc, val) => ({
                        ...acc,
                        [val.path]: val.message,
                    }),
                    {},
                ),
            );
        }
    };

    if (createUser.error || updateUser.error) {
        return <Navigate to="/auth/Login" />;
    }

    return (
        <Form
            renderHeader={() => (
                <InputProfile
                    data={updatedUser}
                    viewOnly={viewOnly}
                    onChange={handleUpdate}
                />
            )}
            data={updatedUser}
            onChange={handleUpdate}
            inputFields={inputFields(
                userById,
                viewOnly,
                viewProfile,
                userRoles,
                policeAdminOptions,
                policeStationOptions,
            )}
            onSubmit={submitData}
            errors={errors}
            showReset={!!mutate}
            onReset={() => {
                setUpdatedUser(userById || {});
                setErrors({});
            }}
            resetLabel={'Reset'}
            submitLabel={
                mutate ? 'Save & Continue' : updating ? 'Update' : 'Add'
            }
            viewOnly={viewOnly}
        />
    );
};

const UserFormCard = ({
    user,
    userRoles,
    viewOnly,
    viewProfile,
    updating,
    policeAdminOptions,
    policeStationOptions,
    mutate,
    skipGeoLocation = false,
}) => {
    let { userId } = useParams();
    const { geoCode } = useGeoCode(
        viewProfile || updating || viewOnly,
        skipGeoLocation,
    );

    const userById = useFetchUserDetailsById(!mutate && userId);

    if (
        userById.isLoading ||
        userById.isFetching ||
        (updating && !userById.data)
    ) {
        return <Spinner />;
    }

    if (userById.error) {
        return <Navigate to="/auth/Login" />;
    }

    if (userById.data?.user_role_id === '4' && viewOnly) {
        return (
            <div className="mt-4">
                <FormView />
            </div>
        );
    }

    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full p-0 md:px-4" style={{ margin: 'auto' }}>
                    <UserForm
                        user={user}
                        userRoles={userRoles}
                        viewOnly={viewOnly}
                        updating={updating}
                        viewProfile={viewProfile}
                        policeAdminOptions={filter(
                            policeAdminOptions,
                            (x) => x.id == 3302,
                        )}
                        policeStationOptions={policeStationOptions}
                        mutate={mutate}
                        userById={
                            viewProfile
                                ? {
                                      ...user,
                                      password: null,
                                  }
                                : updating || viewOnly
                                  ? {
                                        ...userById.data,
                                        password: null,
                                    }
                                  : {
                                        password: null,
                                        user_role_id:
                                            getDefaultRoleId[
                                                user?.user_role_id
                                            ],
                                        is_active: true,
                                        station_longitude: geoCode.longitude,
                                        station_latitude: geoCode.latitude,
                                        ...geoCode,
                                    }
                        }
                    />
                </div>
                {updating && userId && (
                    <>
                        <UserPostingFormCard viewOnly={viewOnly} />
                        <UserDeputationFormCard
                            viewOnly={viewOnly}
                            userById={userById.data}
                        />
                        <UserTrainingFormCard viewOnly={viewOnly} />
                        <UserSportFormCard viewOnly={viewOnly} />
                        <UserPunishmentFormCard viewOnly={viewOnly} />
                        <UserRewardFormCard viewOnly={viewOnly} />
                        <UserSuspensionFormCard viewOnly={viewOnly} />
                        <UserAcrDetailsFormCard viewOnly={viewOnly} />
                        <UserRankJoiningFormCard viewOnly={viewOnly} />
                    </>
                )}
            </div>
        </>
    );
};

export default UserFormCard;
